import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsAddComponent } from './news-add/news-add.component';
import { NewsEditComponent } from './news-edit/news-edit.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsMenuComponent } from './news-menu/news-menu.component';
import { NewsRoutingModule } from './news-routing.module';

@NgModule({
    declarations: [
        NewsAddComponent,
        NewsEditComponent,
        NewsListComponent,
        NewsMenuComponent,
    ],
    imports: [
        CommonModule,
        NewsRoutingModule
    ],
    exports: [
        NewsAddComponent,
        NewsEditComponent,
        NewsListComponent,
        NewsMenuComponent,
    ]
})
export class NewsModule { }
