import { Component } from '@angular/core';

@Component({
  selector: 'app-place-edit',

  templateUrl: './place-edit.component.html',
  styleUrl: './place-edit.component.css'
})
export class PlaceEditComponent {

}
