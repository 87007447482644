import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewsAddComponent } from './news-add/news-add.component'; 
import { NewsEditComponent } from './news-edit/news-edit.component';
import { NewsListComponent } from './news-list/news-list.component';

const routes: Routes = [
    { path: 'add', component:  NewsAddComponent },
    { path: 'edit', component:  NewsEditComponent },
    { path: 'list', component:  NewsListComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class NewsRoutingModule { }
