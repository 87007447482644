import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PeopleAddComponent } from './people/people-add/people-add.component';
import { PeopleEditComponent } from './people/people-edit/people-edit.component';
import { PeopleListComponent } from './people/people-list/people-list.component';

import { PlaceAddComponent } from './place/place-add/place-add.component';
import { PlaceEditComponent } from './place/place-edit/place-edit.component';
import { PlaceListComponent } from './place/place-list/place-list.component';

import { StoryListComponent } from './story/story-list/story-list.component';

import { HistoryRoutingModule } from './history-routing.module';


@NgModule({
    declarations: [
        PeopleAddComponent,
        PeopleEditComponent,
        PeopleListComponent,
        PlaceAddComponent,
        PlaceEditComponent,
        PlaceListComponent,
        StoryListComponent
    ],
    exports: [
        PeopleAddComponent,
        PeopleEditComponent,
        PeopleListComponent,
        PlaceAddComponent,
        PlaceEditComponent,
        PlaceListComponent,
        StoryListComponent
    ],
    imports: [
      CommonModule,
      HistoryRoutingModule
    ]
})
export class HistoryModule { }
