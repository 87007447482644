import { Component } from '@angular/core';

@Component({
  selector: 'app-place-list',

  templateUrl: './place-list.component.html',
  styleUrl: './place-list.component.css'
})
export class PlaceListComponent {

}
