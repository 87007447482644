import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'; // Import RouterModule
import { BodyComponent } from './body/body.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NavComponent } from './menu/nav/nav.component';
import { NavSubComponent } from './menu/nav-sub/nav-sub.component';
import { NavFooterComponent } from './menu/nav-footer/nav-footer.component';

@NgModule({
  declarations: [
    BodyComponent,
    FooterComponent,
    HeaderComponent,
    NavComponent,
    NavFooterComponent,
    NavSubComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    BodyComponent,
    FooterComponent,
    HeaderComponent,
    NavComponent,
    NavFooterComponent,
    NavSubComponent
  ]
})
export class LayoutModule { }
