import { Component } from '@angular/core';

@Component({
  selector: 'app-news-menu',
  templateUrl: './news-menu.component.html',
  styleUrl: './news-menu.component.css'
})
export class NewsMenuComponent {

}
