import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { MenuService } from 'src/app/_services/menu.service';

interface NavItem {
    text: string;
    link?: string;
    icon?: string;
    children?: NavItem[];
    action?: () => void;
}

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {
    menu: NavItem[] = [];
    isMenuVisible: boolean = false;
    private menuSubscription: Subscription = new Subscription();
    selectedMenuItem: string = '';
    isAuthenticated: boolean = false;
    activeItem: NavItem | null = null;

    constructor(
        private authService: AuthService,
        private menuService: MenuService
    ) {
        this.menuSubscription = new Subscription();
    }

    ngOnInit(): void {
        this.menuSubscription = this.menuService.getMenuVisible().subscribe(visible => {
            this.isMenuVisible = visible;
        });

        // Subscribe to the authentication status
        this.authService.isAuthenticated().subscribe(isAuth => {
            this.isAuthenticated = isAuth;
            this.updateMenu(); // Call to update the menu based on authentication status
        });
    }

    ngOnDestroy(): void {
        this.menuSubscription.unsubscribe();
    }

    toggleMenu(item?: NavItem): void {
        this.menuService.toggleMenu();
    }
    
    selectMenuItem(item: any): void {
        this.selectedMenuItem = item.link;
    }

    updateMenu(): void {
        // Define the base menu items here
        this.menu = [
            {
                text: 'Home',
                link: '/home'
            },
            /*
            {
                text: 'News',
                link: '/news/list',
    
            },
            */
            {
                text: 'Food',
                link: '/food/list',
    
            },
            {
                text: 'The Land',
                link: '/grow/list',
    
            },
            {
                text: 'the arts',
                link: '/arts/list',
    
            },
            {
                text: 'Market',
                link: '/market/list',
    
            },
            {
                text: 'Events',
                link: '/event/list',
    
            },
            {
                text: 'stay, dine & discover',
                link: '/discover/list',
            },
            {
                text: 'Jobs',
                link: '/jobs/list',
            },
            {
                text: 'About Patton',
                link: '/about/list'
            }
            /*
            {
                text: 'deals',
                link: '/deals/list',
            },
            {
                text: 'Directory',
                link: '/directory/list',
    
            },
            */
        ];

        // Conditionally add 'create an account' or 'logout'
        if (this.isAuthenticated) {
            this.menu.push({ text: 'Logout', action: () => this.logout() });
        } else {
            this.menu.push({ text: 'Create an Account', link: '/register' });
            this.menu.push({ text: 'Login', link: '/login' });

        }
    }
    
    logout(): void {
        this.authService.logout();
    }    
}
