import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketAddComponent } from './market-add/market-add.component';
import { MarketEditComponent } from './market-edit/market-edit.component';
import { MarketListComponent } from './market-list/market-list.component';
import { MarketMenuComponent } from './market-menu/market-menu.component';
import { MarketRoutingModule } from './market-routing.module';

@NgModule({
    declarations: [
        MarketAddComponent,
        MarketEditComponent,
        MarketListComponent,
        MarketMenuComponent,
    ],
    imports: [
        CommonModule,
        MarketRoutingModule
    ],
    exports: [
        MarketAddComponent,
        MarketEditComponent,
        MarketListComponent,
        MarketMenuComponent,
    ]
})
export class MarketModule { }
