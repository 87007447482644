import { Component, OnInit } from '@angular/core';
import { MenuSubService } from 'src/app/_services/menu-sub.service';

@Component({
  selector: 'app-nav-sub',
  templateUrl: './nav-sub.component.html',
  styleUrls: ['./nav-sub.component.css']
})
export class NavSubComponent implements OnInit {
  subMenuOptions: any[] = [];

  constructor(private menuSubService: MenuSubService) { }

  ngOnInit(): void {
    this.menuSubService.subMenuOptions$.subscribe(options => {
      this.subMenuOptions = options;
    });
  }
}
