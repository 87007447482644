import { Component } from '@angular/core';

@Component({
  selector: 'app-deals-list',
  templateUrl: './deals-list.component.html',
  styleUrl: './deals-list.component.css'
})
export class DealsListComponent {

}
