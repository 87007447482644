// src/app/_services/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { facebookAuthConfig, getAuthConfig, googleAuthConfig } from './auth.config';
import { environment } from 'src/environments/environment.prod';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
    private userDetailsSubject = new BehaviorSubject<any>(null);
    private userFirstName = new BehaviorSubject<string>(sessionStorage.getItem('firstname') || '');
    private userLastName = new BehaviorSubject<string>(sessionStorage.getItem('lastname') || '');
    private userFullName = new BehaviorSubject<string>(sessionStorage.getItem('fullname') || '');
    private userPicture = new BehaviorSubject<string>(sessionStorage.getItem('picture') || '');

    constructor(
        private oauthService: OAuthService,
        private http: HttpClient
    ) {
        if (environment.useFakeAuth) {
            this.fakeLogin();
        } else {
            this.checkIfUserIsLoggedIn();
        }
    }

    private checkIfUserIsLoggedIn() {
        this.configureOAuth('google');
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(result => {
            if (result) {
                console.log('User is already logged in.');
                this.handleSuccessfulLogin();
            } else {
                console.log('User is not logged in.');
            }
        }).catch(error => {
            console.error('Error during login check', error);
        });
    }

    private configureOAuth(provider: 'google' | 'facebook') {
        const config = getAuthConfig(provider);
        this.oauthService.configure(config);
        
        if (provider === 'google') {
            this.oauthService.loadDiscoveryDocument();
        }
    }
        
    getUserFirstName(): Observable<string> {
        return this.userFirstName.asObservable();
    }
    
    getUserLastName(): Observable<string> {
        return this.userLastName.asObservable();
    }
    
    getUserFullName(): Observable<string> {
        return this.userFullName.asObservable();
    }
    
    getUserPicture(): Observable<string> {
        return this.userPicture.asObservable();
    }    

    loginWithGoogle() {
        this.configureOAuth('google');
        this.oauthService.initImplicitFlow();
    }

    loginWithFacebook() {
        const config = getAuthConfig('facebook');
        const clientId = config.clientId || 'defaultClientId';
        const redirectUri = config.redirectUri || 'defaultRedirectUri';
    
        const url = `https://www.facebook.com/dialog/oauth?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=email,public_profile&response_type=token`;
        window.location.href = url;
    }
        
    handleSuccessfulLogin() {
        const token = sessionStorage.getItem('access_token');
        this.isAuthenticatedSubject.next(true);
        this.storeUserDetails();

        const jdata = sessionStorage.getItem('id_token_claims_obj');
        if (jdata) {
            const uData = JSON.parse(jdata);
            const { email, given_name, family_name, picture, locale } = uData;
            this.setUserFirstName(given_name);
            this.setUserFullName(given_name + " " + family_name);
            this.setUserLastName(family_name);
            this.setUserPicture(picture);

            // Avoid sending the token automatically
            // Provide a method to send the token manually
        } else {
            console.log('no user data in id_token_claims_obj');
        }
    }

    sendTokenToBackend() {
        const token = sessionStorage.getItem('access_token');
        const jdata = sessionStorage.getItem('id_token_claims_obj');
        if (jdata && token) {
            const uData = JSON.parse(jdata);
            const { email, given_name, family_name, picture, locale } = uData;
            this.sendToken(token, email, given_name, family_name, picture, locale).subscribe({
                next: (response) => console.log('Token sent successfully', response),
                error: (error) => console.error('Error sending token', error)
            });
        } else {
            console.error('No access token or user data available');
        }
    }

    logout() {
        this.oauthService.logOut();
        sessionStorage.clear();
        this.isAuthenticatedSubject.next(false);
        this.userDetailsSubject.next(null);
        this.resetUserDetails();
    }

    private resetUserDetails() {
        this.setUserFirstName('');
        this.setUserFullName('');
        this.setUserLastName('');
        this.setUserPicture('');
    }

    sendToken(token: string, email: string, name_first: string, name_last: string, picture: string, locale: string) {
        const url = `${environment.apiBaseUrl}/api/auth/google/login/`;
        return this.http.post(url, { token, email, name_first, name_last, picture, locale });
    }

    isAuthenticated(): Observable<boolean> {
        return this.isAuthenticatedSubject.asObservable();
    }

    userDetails(): Observable<any> {
        return this.userDetailsSubject.asObservable();
    }

    private storeUserDetails() {
        const jdata = sessionStorage.getItem('id_token_claims_obj');
        if (jdata) {
            const uData = JSON.parse(jdata);
            this.userDetailsSubject.next(uData);
        } else {
            console.log('no user data in id_token_claims_obj');
        }
    }

    setUserFirstName(firstName: string) {
        sessionStorage.setItem('firstname', firstName);
        this.userFirstName.next(firstName);
    }

    setUserLastName(lastName: string) {
        sessionStorage.setItem('lastname', lastName);
        this.userLastName.next(lastName);
    }

    setUserFullName(fullName: string) {
        sessionStorage.setItem('fullname', fullName);
        this.userFullName.next(fullName);
    }

    setUserPicture(picture: string) {
        sessionStorage.setItem('picture', picture);
        this.userPicture.next(picture);
    }

    // Fake login method for development
    private fakeLogin() {
        const mockUser = {
            email: 'mockuser@example.com',
            given_name: 'Mock',
            family_name: 'User',
            picture: 'https://via.placeholder.com/150',
            locale: 'en'
        };

        sessionStorage.setItem('id_token_claims_obj', JSON.stringify(mockUser));
        sessionStorage.setItem('access_token', 'mock-token');
        this.handleSuccessfulLogin();
    }
}
