import { Component } from '@angular/core';

@Component({
  selector: 'app-people-list',

  templateUrl: './people-list.component.html',
  styleUrl: './people-list.component.css'
})
export class PeopleListComponent {

}
