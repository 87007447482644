<!-- nav.component.html -->
<div class="menu-nav-content">
    <ul class="menu-nav-ul">
        <li *ngFor="let item of menu">
            <a *ngIf="!item.action" [routerLink]="[item.link]" (click)="selectMenuItem(item)" [class.selected-menu-item]="selectedMenuItem === item.link">
                <i *ngIf="item.icon" class="{{item.icon}}"></i>
                {{ item.text }}
            </a>
            <a *ngIf="item.action" (click)="item.action()" [class.selected-menu-item]="selectedMenuItem === item.link">
                <i *ngIf="item.icon" class="{{item.icon}}"></i>
                {{ item.text }}
            </a>
        </li>
    </ul>
</div>
<!-- COMPACT MENU -->
<div class="compact-menu-nav-content" [ngStyle]="{'display': isMenuVisible ? 'block' : 'none'}">
    <ul class="compact-menu-nav-ul">
        <li *ngFor="let item of menu" style="text-align: right;">
            <!-- Check if there's an action and bind to it; otherwise, use routerLink and toggleMenu -->
            <div *ngIf="item.action" (click)="item.action()">
                <i *ngIf="item.icon" class="compact-{{item.icon}}"></i>
                {{ item.text }}
            </div>
            <div *ngIf="!item.action" [routerLink]="[item.link]" (click)="toggleMenu()">
                <i *ngIf="item.icon" class="compact-{{item.icon}}"></i>
                {{ item.text }}
            </div>
        </li>
    </ul>
</div>

