import { Component } from '@angular/core';

@Component({
  selector: 'app-market-list',
  templateUrl: './market-list.component.html',
  styleUrl: './market-list.component.css'
})
export class MarketListComponent {

}
