import { Component } from '@angular/core';

@Component({
  selector: 'app-business-add',
  templateUrl: './business-add.component.html',
  styleUrl: './business-add.component.css'
})
export class BusinessAddComponent {

}
