import { Component } from '@angular/core';

@Component({
  selector: 'app-business-menu',
  templateUrl: './business-menu.component.html',
  styleUrl: './business-menu.component.css'
})
export class BusinessMenuComponent {

}
