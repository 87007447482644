import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PeopleListComponent } from './people/people-list/people-list.component';
import { PlaceListComponent } from './place/place-list/place-list.component';
import { StoryListComponent } from './story/story-list/story-list.component';

const routes: Routes = [
  { path: 'people', component:  PeopleListComponent },
  { path: 'places', component:  PlaceListComponent },
  { path: 'story', component:  StoryListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HistoryRoutingModule { }
