import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { MenuService } from 'src/app/_services/menu.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent {
    // Initial state of the menu can be set here
    isAuthenticated: boolean = false;
    isMenuVisible: boolean = false;
    userFirstName: string = '';
    userLastName: string = '';
    userFullName: string = '';
    userPicture: string = '';

    constructor(
        private authService: AuthService,
        private menuService: MenuService,
        private router: Router
    ) {
        this.authService.isAuthenticated().subscribe(isAuth => {
            this.isAuthenticated = isAuth;
            this.authService.getUserFirstName().subscribe(firstName => {
                this.userFirstName = firstName;
            });
        
            this.authService.getUserLastName().subscribe(lastName => {
                this.userLastName = lastName;
            });
        
            this.authService.getUserFullName().subscribe(fullName => {
                this.userFullName = fullName;
            });
        
            this.authService.getUserPicture().subscribe(picture => {
                this.userPicture = picture;
            });
        });
    }

    logout(): void {
        this.authService.logout();
        // this.router.navigate(['/login']);
    }

    navigateToLogin(): void {
      this.router.navigate(['/login']);
    }
    
    toggleMenu(): void {
        this.menuService.toggleMenu();
    }
}
