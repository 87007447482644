import { Component } from '@angular/core';

@Component({
  selector: 'app-people-add',

  templateUrl: './people-add.component.html',
  styleUrl: './people-add.component.css'
})
export class PeopleAddComponent {

}
