import { Component } from '@angular/core';

@Component({
  selector: 'app-deals-add',
  templateUrl: './deals-add.component.html',
  styleUrl: './deals-add.component.css'
})
export class DealsAddComponent {

}
