import { Component, OnInit } from '@angular/core';
import { NavItem } from 'src/app/_interface/nav-item'

@Component({
    selector: 'app-nav-footer',
    templateUrl: './nav-footer.component.html',
    styleUrl: './nav-footer.component.css'
})

export class NavFooterComponent implements OnInit {
    menu: NavItem[] = [];
    isMenuVisible: boolean = false;
    selectedMenuItem: string = '';
    isAuthenticated: boolean = false;
    activeItem: NavItem | null = null;

    ngOnInit(): void {
        this.menu = [

            {
                text: 'About Big Brick',
                link: '/bigbrick/about',
    
            },
        ];
    }

}
