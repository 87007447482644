import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private menuVisible = new BehaviorSubject<boolean>(false);

    toggleMenu(): void {
        this.menuVisible.next(!this.menuVisible.value);
    }

    getMenuVisible(): BehaviorSubject<boolean> {
        return this.menuVisible;
    }
}

/*
headers: new HttpHeaders({
  'Authorization': 'Bearer ' + this.authService.token
})
*/
