import { Component } from '@angular/core';

@Component({
  selector: 'app-event-add',

  templateUrl: './event-add.component.html',
  styleUrl: './event-add.component.css'
})
export class EventAddComponent {

}
