import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DealsRoutingModule } from './deals-routing.module';
import { DealsAddComponent } from './deals-add/deals-add.component';
import { DealsEditComponent } from './deals-edit/deals-edit.component';
import { DealsListComponent } from './deals-list/deals-list.component';
import { DealsMenuComponent } from './deals-menu/deals-menu.component';

@NgModule({
    declarations: [
        DealsAddComponent,
        DealsEditComponent,
        DealsListComponent,
        DealsMenuComponent
    ],
    imports: [
        CommonModule,
        DealsRoutingModule
    ],
    exports: [
        DealsAddComponent,
        DealsEditComponent,
        DealsListComponent,
        DealsMenuComponent
    ]
})
export class DealsModule { }
