import { Component } from '@angular/core';

@Component({
  selector: 'app-news-add',

  templateUrl: './news-add.component.html',
  styleUrl: './news-add.component.css'
})
export class NewsAddComponent {

}
