import { Component } from '@angular/core';

@Component({
  selector: 'app-deals-menu',
  templateUrl: './deals-menu.component.html',
  styleUrl: './deals-menu.component.css'
})
export class DealsMenuComponent {

}
