// app.module.ts
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { OAuthModule } from 'angular-oauth2-oidc';
import { BusinessModule } from './pages/business/business.module';
import { DealsModule } from './pages/deals/deals.module';
import { EventModule } from './pages/event/event.module';
import { HistoryModule } from './pages/history/history.module';
import { LayoutModule } from './layout/layout.module';
import { MarketModule } from './pages/market/market.module';
import { NewsModule } from './pages/news/news.module';
import { PagesModule } from './pages/pages.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
  ],

  imports: [
    AppRoutingModule,
    BrowserModule,
    BusinessModule,
    DealsModule,
    EventModule,
    HistoryModule,
    HttpClientModule,
    LayoutModule,
    MarketModule,
    NewsModule,
    OAuthModule.forRoot(),
    PagesModule,
    RouterModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
