import { Component } from '@angular/core';

@Component({
  selector: 'app-people-edit',

  templateUrl: './people-edit.component.html',
  styleUrl: './people-edit.component.css'
})
export class PeopleEditComponent {

}
