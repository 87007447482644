import { Component, OnInit } from '@angular/core';
import { MenuSubService } from 'src/app/_services/menu-sub.service';
import { HISTORY_MENU_OPTIONS } from 'src/app/_config/history-menu';

@Component({
  selector: 'app-story-list',

  templateUrl: './story-list.component.html',
  styleUrl: './story-list.component.css'
})
export class StoryListComponent implements OnInit{

    constructor(
        private menuSubService: MenuSubService
    ) { }

    ngOnInit(): void {
        this.menuSubService.updateSubMenuOptions(HISTORY_MENU_OPTIONS);
    }

}
