import { Component } from '@angular/core';

@Component({
  selector: 'app-market-menu',
  templateUrl: './market-menu.component.html',
  styleUrl: './market-menu.component.css'
})
export class MarketMenuComponent {

}
