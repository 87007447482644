import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DealsAddComponent } from './deals-add/deals-add.component';
import { DealsEditComponent } from './deals-edit/deals-edit.component';
import { DealsListComponent } from './deals-list/deals-list.component';

const routes: Routes = [
    { path: 'add', component: DealsAddComponent },
    { path: 'edit', component: DealsEditComponent },
    { path: 'list', component: DealsListComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DealsRoutingModule { }
