import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessAddComponent } from './business-add/business-add.component';
import { BusinessEditComponent } from './business-edit/business-edit.component';
import { BusinessListComponent } from './business-list/business-list.component';
import { BusinessMenuComponent } from './business-menu/business-menu.component';
import { BusinessRoutingModule } from './business-routing.module';

@NgModule({
    declarations: [
        BusinessAddComponent,
        BusinessEditComponent,
        BusinessListComponent,
        BusinessMenuComponent,
    ],
    imports: [
        CommonModule,
        BusinessRoutingModule
    ],
    exports: [
        BusinessAddComponent,
        BusinessEditComponent,
        BusinessListComponent,
        BusinessMenuComponent,
    ]
})
export class BusinessModule { }
