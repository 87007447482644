import { Component } from '@angular/core';

@Component({
  selector: 'app-market-edit',
  templateUrl: './market-edit.component.html',
  styleUrl: './market-edit.component.css'
})
export class MarketEditComponent {

}
