import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BusinessAddComponent } from './business-add/business-add.component';
import { BusinessEditComponent } from './business-edit/business-edit.component';
import { BusinessListComponent } from './business-list/business-list.component';

const routes: Routes = [
    { path: 'add', component: BusinessAddComponent },
    { path: 'edit', component: BusinessEditComponent },
    { path: 'list', component:  BusinessListComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BusinessRoutingModule { }
