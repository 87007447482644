<table id="neader-table">
    <tr id="header-background">
        <td id="header-logo">
            <a [routerLink]="['/home']">
                <img src="assets/images/logo-patton-today.png" id="today-logo" alt="Banner">
            </a>
        </td>
        <td id="header-avatar" >
            <table class="desktop">
                <tr>
                    <td id="username">{{ userFirstName }}</td>
                    <td *ngIf="userPicture" class="avatar"><img src="{{ userPicture }}"></td>
                    <td *ngIf="!userPicture" class="avatar"><img src="assets/images/user.png"></td>
                  </tr>
            </table>
            <table class="compact">
                <tr>
                    <td class="avatar">
                        <img src="assets/images/menu-burger-patton-today.png" (click)="toggleMenu()">
                    </td>
                </tr>
            </table>
        </td>
</table>
<app-nav></app-nav>
<app-nav-sub></app-nav-sub>
