import { Component } from '@angular/core';

@Component({
  selector: 'app-market-add',
  templateUrl: './market-add.component.html',
  styleUrl: './market-add.component.css'
})
export class MarketAddComponent {

}
